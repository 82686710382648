import { template as template_d75522f0f756451abe720ee15c563e08 } from "@ember/template-compiler";
const FKLabel = template_d75522f0f756451abe720ee15c563e08(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
