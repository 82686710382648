import { template as template_474c1ce86c864d38a743bdd4b842a9a0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_474c1ce86c864d38a743bdd4b842a9a0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
